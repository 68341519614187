import { toast } from "react-toastify";
import http from "../http-common";

export const getCountries = async () => {
  try {
    const { data } = await http.get("/countries");
    return data.result;
  } catch (err) {
    return {
      success: false,
      message: err.response.data.messge || "Cannot get countries",
    };
  }
};

export const getRequirements = async () => {
  const data = await http
    .get("/api_only/customer_services/beneficiary_requirements")
    .then((response) => {
      return response.data.result;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });

  return data;
};

export const getCountryStates = async (params) => {
  const data = await http
    .get(`/countries/states/${params}`)
    .then((response) => {
      return response.data.result;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });

  return data;
};

export const getCountryStateCities = async (params) => {
  const data = await http
    .get(`/countries/states/cities/${params}`)
    .then((response) => {
      return response.data.result;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });

  return data;
};

const countryService = {
  getCountries,
  getRequirements,
  getCountryStates,
  getCountryStateCities,
};

export default countryService;
