import { toast } from "react-toastify";
import http from "../http-common";

//CREATE ROLE
export const createRole = async (params) => {
  const data = await http
    .post(`/roles`, params)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });

  return data;
};
//GET ROLES
export const getRoles = async () => {
  const data = await http
    .get(`/roles`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });

  return data;
};

//UPDATE ROLE
export const updateSingleRole = async (params, id) => {
  const data = await http
    .put(`/roles/${id}`, params)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });

  return data;
};

//DELETE ROLE
export const deleteRole = async (id) => {
  const data = await http
    .delete(`/roles/${id}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });

  return data;
};

export const getDefaultRoles = async () => {
  const data = await http
    .get(`/roles/default_roles`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });

  return data;
};
