import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabs: [{ name: "Dashboard", route: "/admin" }],
  activeTabIndex: 0,
};

export const dynamicTabSlice = createSlice({
  name: "dynamicTab",
  initialState,
  reducers: {
    addTab: (state, action) => {
      const { route, name } = action.payload;
      const existingTab = state.tabs.find((tab) => tab.route === route);

      if (existingTab) {
        return;
      } else if (state.tabs.length === 10) {
        state.tabs.splice(-1, 1);
        state.tabs.push({ route, name });
      } else {
        state.tabs.push({ route, name });
      }
      state.activeTabIndex = state.tabs.length - 1;
    },
    closeTab: (state, action) => {
      state.tabs.splice(action.payload, 1);
      state.activeTabIndex = state.tabs.length - 1;
    },
    setActiveTabIndex: (state, action) => {
      state.activeTabIndex = action.payload;
    },
  },
});

export const { addTab, closeTab, setActiveTabIndex } = dynamicTabSlice.actions;
export default dynamicTabSlice.reducer;
