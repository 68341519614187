import React, { useEffect, useState } from "react";
import Select from "react-select";
import countryService from "../../../services/countryService";
import { addLocation } from "../../../services/LocationService";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "../../Backdrop";

function AddDefaultLocation() {
  const { countries, isLoading } = useState((state) => state.countries);
  const [locationCode, setLocationCode] = useState("");
  const [locationType, setLcationType] = useState([]);
  const [locationLimit, setLocationLimit] = useState({
    credit_limit_currency: "",
    i_remit: 5000,
  });
  const [country, setCountry] = useState("");

  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const [currency, setCurrency] = useState("");
  const { register, getValues } = useForm();
  const [vat, setVat] = useState(0);
  const [loading, setLoading] = useState(false);

  const { pending } = useSelector((state) => state.location);

  const dispatch = useDispatch();

  const location_type_options = [
    { value: "sender", label: "Sender" },
    { value: "payee", label: "Payee" },
    { value: "virtual", label: "Virtual" },
  ];
  const country_options = [];

  countries?.map((country, index) => {
    return country_options.push({
      id: country.id,
      value: country.name,
      label: country.name,
      code: country.phonecode,
      currency: country.currency,
    });
  });

  const handleCountry = async (selected) => {
    const data = await countryService.getCountryStates(selected.id);

    data?.map((state, index) => {
      return state_options.push({
        value: state.name,
        label: state.name,
        id: state.id,
      });
    });

    setStateOptions(state_options);

    const rndInt = randomIntFromInterval();
    setCountry(selected.value);
    setCurrency(selected.currency);
    const name =
      selected.code +
      "-" +
      selected.value.substring(0, 3).toUpperCase() +
      "-" +
      rndInt;
    setLocationCode(name);
  };

  const handleLocationType = (selected) => {
    setLcationType(selected);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = getValues();
    const newForm = {
      ...values,
      location_code: locationCode,
      country,
      state,
      city,
      currency,
      location_type: locationType,
      credit_limit_currency: locationLimit.credit_limit_currency,
      i_remit: locationLimit.i_remit,
      vat,
    };
    addLocation(newForm, dispatch);
  };

  const [stateOptions, setStateOptions] = useState([]);
  var state_options = [];

  const [cityOptions, setCityOptions] = useState([]);
  var city_options = [];

  country_options.unshift({
    value: "All",
    label: "All",
  });

  const handleChangeState = async (selectedOption) => {
    setState(selectedOption.value);
    const data = await countryService.getCountryStateCities(selectedOption.id);
    data?.map((city, index) => {
      return city_options.push({
        value: city.name,
        label: city.name,
        id: city.id,
      });
    });

    setCityOptions(city_options);
  };

  const handleChangeCity = async (selectedOption) => {
    setCity(selectedOption.value);
  };
  const onChangeAll = (event) => {
    setVat(event.target.checked);
  };

  if (loading || isLoading) {
    return <Loading />;
  }

  if (pending) {
    return <Loading />;
  }
  return (
    <div className="app-card app-card-settings shadow-sm p-4">
      <div className="app-card-body">
        <form style={{ fontSize: "12px" }}>
          <div className="row mb-3 g-3">
            <div className="col-md-6 border border-1 p-4">
              <div className="col-12 mb-4">
                <h5 className="card-title">Location Information</h5>
              </div>
              <div className="form-group row mb-3">
                <label for="location_code" className="col-form-label col-md-4">
                  Location Code
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="location_code"
                    className="form-control"
                    placeholder="Location Code"
                    readOnly
                    value={locationCode}
                    {...register("location_code")}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label for="location_name" className="col-form-label col-md-4">
                  Location Name
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="location_name"
                    className="form-control"
                    placeholder="Location Name"
                    {...register("location_name")}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label for="address_1" className="col-form-label col-md-4">
                  Address 1:
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="address_1"
                    className="form-control"
                    placeholder="Address 1"
                    {...register("address_1")}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label for="address_2" className="col-form-label col-md-4">
                  Address 2:
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="address_2"
                    className="form-control"
                    placeholder="Address 2"
                    {...register("address_2")}
                  />
                </div>
              </div>

              <div className="form-group row mb-3">
                <label for="country" className="col-form-label col-md-4">
                  Country
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <Select
                    name="country"
                    options={country_options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleCountry}
                  />
                </div>
              </div>

              <div className="form-group row mb-3">
                <label for="province_state" className="col-form-label col-md-4">
                  Currency
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="province_state"
                    className="form-control"
                    placeholder="Currency"
                    value={currency}
                    readOnly
                    {...register("currency")}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label for="province_state" className="col-form-label col-md-4">
                  Province/State:
                </label>
                <div className="col-md-8">
                  {/* <input
                      type='text'
                      id='province_state'
                      className='form-control'
                      placeholder='province_state'
                      {...register("province")}
                    /> */}

                  <Select
                    name="state"
                    options={stateOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleChangeState}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label for="zip_code" className="col-form-label col-md-4">
                  Postal/Zip Code
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="zip_code"
                    className="form-control"
                    placeholder="Zip Code"
                    {...register("zip")}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label for="city" className="col-form-label col-md-4">
                  City:
                </label>
                <div className="col-md-8">
                  {/*  <input
                      type='text'
                      id='city'
                      className='form-control'
                      placeholder='City'
                      {...register("city")}
                    /> */}
                  <Select
                    name="state"
                    options={cityOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleChangeCity}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label for="phone_1" className="col-form-label col-md-4">
                  Phone 1<sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="phone_1"
                    className="form-control"
                    placeholder="Phone 1"
                    {...register("phone_1")}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label for="phone_2" className="col-form-label col-md-4">
                  Phone 2:
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="phone_2"
                    className="form-control"
                    placeholder="Phone 2"
                    {...register("phone_2")}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label for="phone_2" className="col-form-label col-md-4">
                  VAT:
                </label>
                <div className="col-md-8">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={register.vat}
                      id="flexCheckDefault"
                      onChange={onChangeAll}
                      checked={register.vat}
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      Enable VAT
                    </label>
                    {JSON.stringify(register)}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 border border-1 p-4">
              <div className="col-12 mb-4">
                <h5 className="card-title">Location Manager/Contact Person</h5>
              </div>
              <div className="form-group row mb-3">
                <label for="full_name" className="col-form-label col-md-4">
                  Full Name
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="full_name"
                    className="form-control"
                    placeholder="Full Name"
                    {...register("name")}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="contact_person_phone"
                  className="col-form-label col-md-4"
                >
                  Phone
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="contact_person_phone"
                    className="form-control"
                    placeholder="Phone"
                    {...register("phone")}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="contact_person_email"
                  className="col-form-label col-md-4"
                >
                  Email
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <input
                    type="email"
                    id="contact_person_email"
                    className="form-control input-sm"
                    placeholder="Email"
                    {...register("email")}
                  />
                </div>
              </div>
              <hr />
              <div className="form-group row mb-3">
                <label for="location_type" className="col-form-label col-md-4">
                  Location Type
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <Select
                    isMulti
                    name="location_type"
                    options={location_type_options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleLocationType}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label for="gender" className="col-form-label col-md-4">
                  Location Level
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <select
                    className="form-control form-select"
                    id="gender"
                    {...register("location_level")}
                  >
                    <option value="">select location level</option>
                    <option value="L0">L0</option>
                    <option value="L1">L1</option>
                    <option value="L2">L2</option>
                  </select>
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  for="location_status"
                  className="col-form-label col-md-4"
                >
                  Location Status
                  <sup className="text-danger">*</sup>
                </label>
                <div className="col-md-8">
                  <select
                    className="form-control form-select"
                    id="location_status"
                    {...register("location_status")}
                  >
                    <option>select location status</option>
                    <option value="active">Active</option>
                    <option value="inactive">InActive</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <button
                type="submit"
                className="btn btn-primary btn-md mx-1"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* <!--//app-card-body--> */}
    </div>
  );
}

export default AddDefaultLocation;

function randomIntFromInterval() {
  // min and max included
  return Math.floor(Math.random() * 10000);
}
