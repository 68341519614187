import { createSlice } from "@reduxjs/toolkit";

export const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    transactions: [],
    searchedTransactions: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleTransactionRequest: (state) => {
      state.pending = true;
    },
    getTransactionSuccess: (state, action) => {
      state.pending = false;
      state.error = false;
      state.transactions = action.payload;
      state.errorMsg = "";
    },
    searchedTransactionSuccess: (state, action) => {
      state.pending = false;
      state.error = false;
      state.searchedTransactions = action.payload;
      state.errorMsg = "";
    },
    updateTransactionState: (state, action) => {
      const index = state.transactions.findIndex(
        (t) => t.id === action.payload.id
      );
      state.transactions[index].status = action.payload.status;
      state.pending = false;
    },
    handleTransactionError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleTransactionRequest,
  getTransactionSuccess,
  handleTransactionError,
  updateTransactionState,
  searchedTransactionSuccess,
} = transactionSlice.actions;
export default transactionSlice.reducer;
