import http from "../http-common";
import {
  handleLocationRequest,
  getAllLocationSuccess,
  createLocationSuccess,
  handleLocationError,
  getSingleLocationSuccess,
} from "../redux/Slices/locationSlice";
import { toast } from "react-toastify";

export const getAllLocation = (data, dispatch, history) => {
  dispatch(handleLocationRequest());
  http
    .post("/locations/getAll", data)
    .then((response) => {
      dispatch(getAllLocationSuccess(response.data));
      toast.success("Success");
    })
    .catch((error) => {
      dispatch(handleLocationError(error));
      toast.error(error.response.data.message);
    });
};
export const getAllLocationCode = (dispatch, history) => {
  dispatch(handleLocationRequest());
  http
    .get("/locations/getAll/location")
    .then((response) => {
      dispatch(getAllLocationSuccess(response.data));
    })
    .catch((error) => {
      dispatch(handleLocationError(error));
      toast.error(error.response.data.message);
    });
};

export const addLocation = async (data, dispatch, history) => {
  http
    .post("/locations", data)
    .then((response) => {
      dispatch(createLocationSuccess());
      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};
export const getOneLocation = (data, dispatch) => {
  dispatch(handleLocationRequest);
  http
    .get(`/locations/${data}`)
    .then((response) => {
      dispatch(getSingleLocationSuccess(response.data.result));
    })
    .catch((error) => {
      dispatch(handleLocationError(error));
      toast.error(error.response?.data?.message);
    });
};

//GET SINGLE LOCATION BY CODE
export const getSingleLocation = async (params) => {
  const data = await http
    .get(`/locations/${params}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });

  return data;
};

//UPDATE LOCATION
export const updateLocation = async (params) => {
  const data = await http
    .put(`/locations/${params.id}`, params.formData)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });

  return data;
};

//GET TRANSACTION COUNTRY LOCATIONS
export const getCountriesLocation = async (params) => {
  const data = await http
    .post(`/locations/getAll`, params)
    .then((response) => {
      return response.data.result;
    })
    .catch((err) => {
      toast.error(err.response?.data?.message);
    });

  return data;
};

// exchange rate Country location
export const getExchangeRateLocation = async (params) => {
  const data = await http
    .post(`/locations/getAll`, params)
    .then((response) => {
      return response.data.result;
    })
    .catch((err) => {
      toast.error(err.response?.data?.message);
    });

  return data;
};

export const getCountryLocations = async (params) => {
  const data = await http
    .post(`/locations/getAll`, params)
    .then((response) => {
      return response.data.result;
    })
    .catch((err) => {
      toast.error(err.response?.data?.message);
    });

  return data;
};

export const getLocationModuleData = async (params) => {
  // declear a variable to hold the data from the api

  const data = await http
    .get(`/locations/getLocationSnapshot/${params}`)

    .then((res) => {
      return res.data;
    })

    .catch((err) => {
      toast.error(err.response?.data?.message);
    });

  return data;
};

export const getAllLocationPartner = async () => {
  const data = await http
    .get(`/locations/getAll/partners/`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response?.data?.message);
    });

  return data;
};
