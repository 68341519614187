import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Loading from "../../components/Backdrop";
import { logout } from "../../redux/Slices/Auth";
import { toast } from "react-toastify";
import { verifyUser } from "../../services/authService";
import { useRef } from "react";
import countryService from "../../services/countryService";
import {
  resetCountriesIsLoading,
  setCountries,
  setCountriesIsLoading,
} from "../../redux/Slices/countriesSlice";

const OtherComponent = React.lazy(() => import("./Layout"));
function Admin() {
  const { auth, session } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const authRef = useRef(auth);

  const handleLogout = (e) => {
    localStorage.clear();
    window.location.pathname = "/";
  };

  const handleSession = async () => {
    if (!localStorage.getItem("session")) {
      history.push("/");
    } else {
      if (!auth?.id) {
        verifyUser(dispatch);
      } else {
        setShow(true);
        history.push(location.pathname);
      }
    }
  };

  useEffect(() => {
    handleSession();
  }, [auth?.id, session]);

  const fetchCountries = async () => {
    dispatch(setCountriesIsLoading());
    const data = await countryService.getCountries();
    if (data.success === false)
      toast(data.message, {
        type: "error",
      });
    else {
      dispatch(setCountries(data));
    }

    dispatch(resetCountriesIsLoading());
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  // const [timeoutId, setTimeoutId] = useState(null);
  // const timeoutInMinutes = 30;

  // const resetTimeout = () => {
  //   if (timeoutId) {
  //     clearTimeout(timeoutId);
  //   }

  //   const newTimeoutId = setTimeout(() => {
  //     handleLogout();
  //   }, timeoutInMinutes * 60 * 1000); // Convert minutes to milliseconds

  //   setTimeoutId(newTimeoutId);
  // };

  // const handleActivity = () => {
  //   resetTimeout();
  // };

  // useEffect(() => {
  //   // Add event listeners for user activity
  //   window.addEventListener("mousemove", handleActivity);
  //   window.addEventListener("keydown", handleActivity);

  //   // Initialize the timeout
  //   resetTimeout();

  //   // Cleanup event listeners on component unmount
  //   return () => {
  //     window.removeEventListener("mousemove", handleActivity);
  //     window.removeEventListener("keydown", handleActivity);
  //   };
  // }, [timeoutId, handleLogout, timeoutInMinutes]);

  return show ? (
    <div>
      <Suspense fallback={<Loading />}>
        <OtherComponent />
      </Suspense>{" "}
    </div>
  ) : (
    <Loading />
  );
}

export default Admin;
