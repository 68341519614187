import axios from "axios";

const axiosConfig = axios.create({
  // baseURL: "https://con.immedi8money.com/api/v1",

  baseURL: "https://api.afiafinancialservices.com/api/v1",
  // baseURL: "http://localhost:5003/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosConfig.interceptors.request.use(function (config) {
  let token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default axiosConfig;
