import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "user",
  initialState: {
    update: Date.now(),
    auth: null,
    location: null,
    permission: null,
    session: localStorage.getItem("session"),
    token: localStorage.getItem("token"),
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    loginRequest: (state) => {
      state.pending = true;
    },
    CancelPending: (state) => {
      state.pending = false;
    },
    registerRequest: (state) => {
      state.pending = true;
    },
    loginSuccess: (state, action) => {
      const { user, permission, session } = action.payload;
      state.pending = false;
      state.auth = user;
      state.permission = permission;
      state.error = false;
      state.errorMsg = "";
      localStorage.setItem("session", session);
    },
    registerSuccess: (state, action) => {
      state.pending = false;
      // state.user = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    loginError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
      localStorage.removeItem("session");
    },

    loginErrorVerify: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
      localStorage.removeItem("token");
      localStorage.removeItem("session");
      window.location.href = "/";
    },
    registerError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    updateState: (state, action) => {
      state.update = Date.now();
    },
    logout: (state) => {
      state.error = false;
      state.auth = null;
      localStorage.clear();
      window.location.href = "/";
    },
  },
});

export const {
  loginRequest,
  registerRequest,
  loginSuccess,
  CancelPending,
  registerSuccess,
  registerError,
  loginError,
  loginErrorVerify,
  updateState,
  logout,
} = authSlice.actions;
export default authSlice.reducer;
