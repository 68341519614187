import http from "../http-common";

import {
  CancelPending,
  loginError,
  loginErrorVerify,
  loginRequest,
  loginSuccess,
  logout,
} from "../redux/Slices/Auth";
import { toast } from "react-toastify";
import { getOneLocation } from "./LocationService";

export const loginUser = (user, dispatch, history) => {
  dispatch(loginRequest());
  http
    .post("/login", user)
    .then((response) => {
      localStorage.setItem("token", response.data.token);
      history.push("/check-authorization");
    })
    .catch((error) => {
      toast.error(error.response?.data?.message);
      dispatch(loginError(error));
    });
};

export const nextView = async () => {
  try {
    return await http.get("/users/check_user_table");
  } catch (error) {
    toast.error(error.response.data.message);
    return error.response;
  }
};

//From token || localStorage, verify user session.
export const middleware = async (dispatch) => {
  dispatch(CancelPending());
  try {
    const response = await http.get("/users/user_2fa_promt");
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch(loginErrorVerify(error));
  }
};

//Session
export const verifySession = async (session) => {
  try {
    return await http.post("/users/session", { session });
  } catch (error) {
    return error.response.data;
  }
};

//From token || localStorage, verify user session.
export const verifyUser = (dispatch) => {
  if (!localStorage.getItem("token")) {
    window.location = "/";
  }
  http
    .get("/users/verify")
    .then((response) => {
      dispatch(loginSuccess(response.data.result));
      getOneLocation(response.data.result.user.location, dispatch);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      // dispatch(loginErrorVerify(error));
    });
};

export const registerUser = async (user) => {
  const data = await http
    .post(`/register`, user)
    .then((response) => {
      toast.success(response.data.message);
      window.location.reload();
      return response.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);

      return err.response.data.message;
    });

  return data;
};

// export const logout = () => {};
